<template>
  <div
    class="favorite-icon-wrapper"
    :class="favoriteIconClass"
    @click.prevent="handleFavoriteClick"
  >
    <div class="inner-wrapper">
      <StIcon
        class="icon"
        :class="{ 'favorite-icon': isFavoriteGame }"
        :name="isFavoriteGame ? 'heart-solid' : 'heart'"
        :size="16"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFavoriteGame } from '../../../composables/useFavoriteGame'

const props = defineProps<{ id: number; mobile?: boolean }>()

const { handleFavoriteClick, shakeAnimation, isFavoriteGame } = useFavoriteGame(
  computed(() => props.id),
)

const favoriteIconClass = computed(() => [
  { shake: shakeAnimation.value },
  { 'favorite-icon-active': isFavoriteGame.value },
  { mobile: props.mobile },
])
</script>

<style scoped>
.favorite-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: var(--spacing-050);

    background-color: rgb(0 0 0 / 48%);
    backdrop-filter: blur(4px);
    border-radius: var(--border-radius-full);
  }

  .icon {
    min-width: 0;
    background-color: var(--icon-secondary);
    transition: background-color 0.2s ease-in-out;
  }

  &:hover .icon {
    background-color: var(--icon-primary);
  }

  &.shake {
    .inner-wrapper {
      animation: shake 0.3s ease-in-out;
    }
  }

  &.favorite-icon-active {
    .inner-wrapper {
      background-color: #fb1038; /* stylelint-disable-line */
      transition: background-color 0.2s ease-in-out;
    }

    .icon {
      background-color: var(--icon-primary);
    }

    &:hover {
      .inner-wrapper {
        background-color: #e00c30; /* stylelint-disable-line */
      }
    }
  }

  &.mobile {
    position: absolute;
    z-index: 2;
    right: 0;
    margin: var(--spacing-050);
  }
}

@keyframes shake {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
